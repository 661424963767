import React from 'react';
import './index.css';
import Valtozok from './Valtozok.js'

class ShowHide extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showResults: false };
        this.onClick = this.onClick.bind(this);
    }
    onClick() {
      if (this.state.showResults) {
        this.setState({ showResults: false });
      }
      else {
        this.setState({ showResults: true });
      }
    }
    render() {
        return (
            <div>
                <input type="submit" className="showgomb" value={this.state.showResults ? 'Eltüntet' : 'Megjelenít'} onClick={this.onClick} />
                { this.state.showResults ? <Valtozok /> : null }
            </div>
        );
    }
}

export default ShowHide;
