import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import Progress from 'react-progressbar';

class TextInternalSelectQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    var array = {};
    for (var i = 0; i < props.options.length; i++) {
      array[i] = {value: '', label: ''};
    }
    this.state = array;
  }
  handleChange(num, event) {
    let stateCopy = Object.assign({}, this.state);
    var found = -1;
    for (var i = 0; i < this.props.options[num].length; i++) {
      if (this.props.options[num][i].value === event.target.value) {
        found = i;
      }
    }
    if (found === -1) {
      stateCopy[num] = {value: '', label: ''};
    } else {
      stateCopy[num] = {value: this.props.options[num][found].value, label: this.props.options[num][found].label};
    }
    this.setState(stateCopy);
    this.props.onChangeValue(stateCopy);
  }
  generateSelect(options, num) {
    var opts = [];
    for (var i = 0; i < options.length; i++) {
      opts.push(<option key={options[i].value} value={options[i].value}>{options[i].label}</option>);
    }
    return (<span key={num*2+1}>&nbsp;
      <select onChange={(e) => this.handleChange(num, e)}>
        <option value=''></option>
        {opts}
      </select>
      &nbsp;</span>);
  }
  render() {
    var percent = (this.props.num-0.95) / this.props.all * 100;
    var elements = [];
    for (var i = 0; i < this.props.text.length-1; i++) {
      elements.push(<span key={i*2} dangerouslySetInnerHTML={{__html: this.props.text[i]}}/>);
      elements.push(this.generateSelect(this.props.options[i], i));
    }
    elements.push(this.props.text[this.props.text.length-1]);
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            <h1>{this.props.num}. kérdés</h1>
            <Progress completed={percent} color={"#1380AE"}/>
            {/*<h2>Összesen {this.props.all} kérdés</h2>*/}
          </div>
        </div>
        <div className="hatter">
          <div className="questionbox">
            <form onSubmit={this.props.onEvaluate}>
              <p className="textBox">{elements}</p>
              <input type="submit" value="Következő" className="kovgomb"/>
              <p className="remark">Előző kérdésre visszatérni nem lehet!</p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default TextInternalSelectQuiz;
