import React from 'react';
import './index.css';

//import Accordion from './Accordion.js';

class InfoPage extends React.Component {
  render() {
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            <h1>{this.props.title}</h1>
          </div>
        </div>
        <div className="hatter">
          <div className="questionbox">
          <p className="textBox" dangerouslySetInnerHTML={{__html: this.props.text}}></p>
        </div>
      </div>
    </div>);
  }
}



export default InfoPage;
