import React from 'react';
import './index.css';

class ResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.getButtons = this.getButtons.bind(this)
  }
  getButtons() {
    var ret = [];
    if (parseInt(this.props.mmdata.elso_teszt_pontszam,10) >= 55 &&
        parseInt(this.props.mmdata.teszt_pontszam,10) >= 55 &&
        this.props.mmdata.regisztracio_java_se === '0000-00-00 00:00:00') {
          //ret.push(<span key="1"><a href={"https://auto.studicore.hu/integration/elegjo-teszt-d4f12c.php?email="+this.props.mmdata.email} className="gomb">Regisztrálok az ajándékért</a><br/></span>)
    }
    if (this.props.mmdata.regisztracio_java_se !== '0000-00-00 00:00:00') {
      ret.push(<a key="2" href="https://online.studicore.hu" className="gomb">Tovább a StudiCore Online-ra</a>)
    }
    return ret;
  }
  render() {
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            <h1>Teszteredmény</h1>
          </div>
        </div>
        <div className="hatter">
          <div className="questionbox">
          <p>A tesztet már kitöltötted korábban.</p>
          <table className="resulttable">
          <tbody>
          <tr>
          <th>Első kitöltés ideje</th>
          <td>{this.props.mmdata.elso_tesztkitoltes_ideje}</td>
          </tr>
          <tr>
          <th>Első kitöltés pontszáma</th>
          <td>{this.props.mmdata.elso_teszt_pontszam}</td>
          </tr>
          <tr>
          <th>Utolsó kitöltés ideje</th>
          <td>{this.props.mmdata.tesztkitoltes_ideje}</td>
          </tr>
          <tr>
          <th>Utolsó kitöltés pontszáma</th>
          <td>{this.props.mmdata.teszt_pontszam}</td>
          </tr>
          </tbody>
          </table>
          <form onSubmit={this.props.onEvaluate}>
          <input type="submit" value="Kitöltöm újra!" className="gomb"/>
          </form>
          {this.getButtons()}
        </div>
      </div>
    </div>);
  }
}

export default ResultPage;
