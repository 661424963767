import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import Progress from 'react-progressbar';

class CheckBoxQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    var array = {};
    for (var i = 0; i < props.options.length; i++) {
      array[props.options[i].value] = false;
    }
    this.state = array;
  }
  handleChange(event) {
    //alert(event.target.value + ' ' + event.target.checked);
    let key = event.target.value;
    let val = event.target.checked;
    let obj = {};
    obj[key] = val;
    let stateCopy = Object.assign({}, this.state);
    stateCopy[key] = val;
    //alert(stateCopy.toSource());
    this.setState(stateCopy);
    this.props.onChangeValue(stateCopy);
  }
  render() {
    var percent = (this.props.num-0.95) / this.props.all * 100;
    var elements = [];
    for (var i = 0; i < this.props.options.length; i++) {
      elements.push(
        <div key={i}>
          <input type="checkbox" className="checkboxquiz-input"
            name={"cbq"+i} value={this.props.options[i].value}
            onChange={this.handleChange}
            id={"cbq"+i}/>
          <label htmlFor={"cbq"+i}>
            {this.props.options[i].label}
          </label>
        </div>
      );
    }
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            <h1>{this.props.num}. kérdés</h1>
            <Progress completed={percent} color={"#1380AE"}/>
            {/*<h2>Összesen {this.props.all} kérdés</h2>*/}
          </div>
        </div>
        <div className="hatter">
          <div className="questionbox">
            <div className="textBox">
              {this.props.text}
            </div>
            <p>Válassz ki egyet vagy többet:</p>
            <form onSubmit={this.props.onEvaluate}>
              <div className="checkboxes">
                {elements}
              </div>
              <p><input type="submit" value="Következő" className="kovgomb"/></p>
            </form>
            <p className="remark">Előző kérdésre visszatérni nem lehet!</p>
          </div>
        </div>
      </div>
    );
  }
}
export default CheckBoxQuiz;
