import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import Progress from 'react-progressbar';

class TextAreaQuiz extends React.Component {
  constructor(props) {
    super(props);
    let initialValue = props.initial.replace(/\\n/g, '\n');
    this.state = { value: initialValue };
    this.props.onChangeValue(initialValue);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    this.setState({value: ev.target.value});
    this.props.onChangeValue(ev.target.value);
  }

  render() {
    var percent = (this.props.num-0.95) / this.props.all * 100;
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            <h1>{this.props.num}. kérdés</h1>
            <Progress completed={percent} color={"#1380AE"}/>
            {/*}<h2>Összesen {this.props.all} kérdés</h2>*/}
          </div>
        </div>
        <div className="hatter">
      <div className="questionbox">
        <div className="textBox">{this.props.text}</div>
        <form onSubmit={this.props.onEvaluate}>
          <div className="textarea-wrapper">
            <textarea value={this.state.value} onChange={this.handleChange} rows="10"/>
          </div>
          <p><input type="submit" value="Következő" className="kovgomb"/></p>
          <p className="remark">Előző kérdésre visszatérni nem lehet!</p>
        </form>
      </div>
    </div>
  </div>
    );
  }
}

export default TextAreaQuiz;
