import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import Progress from 'react-progressbar';
//import { Accordion, AccordionItem } from 'react-light-accordion';
//import 'react-light-accordion/demo/css/index.css';


class InputQuiz extends React.Component {
  /*constructor(props) {
    super(props);
  }*/
  render() {
    var percent = (this.props.num-0.95) / this.props.all * 100;
    //console.log(percent);
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <span className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </span>
            <h1>{this.props.num}. kérdés</h1>
            <Progress completed={percent} color={"#1380AE"}/>
          </div>
        </div>
        <div className="hatter">
          <div className="questionbox">
          <p className="textBox" dangerouslySetInnerHTML={{__html: this.props.text}}></p>
          <form onSubmit={this.props.onEvaluate}>
            <label htmlFor="valasz">
              Válasz:
            </label>
            <div className="valasz-wrapper">
              <input type="text" id="valasz" className="valasz" value={this.props.value} onChange={this.props.onChange} size={this.props.inputSize}/>
            </div>
            <p><input type="submit" value="Következő" className="kovgomb"/></p>
            <p className="remark">Előző kérdésre visszatérni nem lehet!</p>
          </form>
        </div>
      </div>
    </div>);
  }
}

export default InputQuiz;
