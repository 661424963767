import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import InfoPage from './InfoPage';
import ResultPage from './ResultPage';
import CheckBoxQuiz from './CheckBoxQuiz';
import InputQuiz from './InputQuiz';
import TextInternalSelectQuiz from './TextInternalSelectQuiz';
//import DragAndDropQuiz from './DragAndDropQuiz';
import DragAndDropQuiz2 from './DragAndDropQuiz2';
//import RadioQuiz from './RadioQuiz';
import TextAreaQuiz from './TextAreaQuiz';
import Evaluation from './Evaluation';
import axios from 'axios';
import queryString from 'query-string';
import ShowHide from './ShowHide.js'
//import $ from 'jquery';

class ProgQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.createState(-100);
    //this.state = this.createState(18);

    const parsed1 = queryString.parse(window.location.search);
    const email = parsed1.email;
    const v = parsed1.v;
    console.log(v);
    if (email == null) {
      this.state = {num: -1};
    } else {
      let data = new FormData();
      data.append('email', email);
      var self = this;
      axios.post('https://auto.studicore.hu/integration/getter_mm_test_data.php', data)
      //axios.post('http://localhost:8100/integration/getter_mm_test_data.php', data)
      .then(function(response) {
        //console.log(response);
        if (response.data === '') {
          self.setState({num: -2});
        }
        else if (response.data.tesztkitoltes_ideje !== '0000-00-00 00:00:00' && v === undefined) {
          self.setState({num: 0, mmdata: response.data});
        } else {
          self.setState({num: 1, mmdata: response.data});
        }
      })
      .catch(function(error) {
        console.log(error);
      })
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeToValue = this.handleChangeToValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.createState = this.createState.bind(this);
    this.restartTest = this.restartTest.bind(this);
  }

  handleSubmit(q, event) {
    //console.log("Lefutott");
    this.setState({num: this.state.num+1});
    event.preventDefault();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  handleChangeToValue(q, newValue) {
      let ans = this.state.answers;
      ans[q] = {'value': newValue};
      this.setState({answers: ans});
      //console.log(answers);
  }
  handleChange(q, event) {
    this.handleChangeToValue(q, event.target.value);
  }
  handleNext(q, event) {
    this.setState({num: this.state.num+1});
  }
  createState(startNum) {
    return {value: '', num: startNum, answers:
      {
        1: {value: ""},
        2: {value: {'a': false, 'b': false, 'c': false, 'd': false, 'e': false, 'f': false}},
        3: {value: ""},
        4: {value: {0:{value:"", label:"semmi"}, 1:{value:"", label:"semmi"}, 2:{value:"", label:"semmi"}, 3:{value:"", label:"semmi"},
                    4:{value:"", label:"semmi"}, 5:{value:"", label:"semmi"}, 6:{value:"", label:"semmi"}, 7:{value:"", label:"semmi"},
                    8:{value:"", label:"semmi"}, 9:{value:"", label:"semmi"}}},
        5: {value: {0:'semmi', 1:'semmi', 2:'semmi', 3:'semmi', 4:'semmi', 5:'semmi', 6:'semmi', 7:'semmi'}},
        6: {value: {'a': false, 'b': false, 'c': false, 'd': false, 'e': false, 'f': false, 'g': false}},
        7: {value: "semmi"}
        // beírni a többi default értéket is?
      }};
  }
  restartTest() {
    //console.log("restartTest");
    this.setState(this.createState(1));
  }
  render() {
    //console.log(this.state.mmdata);
    var all = "7";
    if (this.state.num === -100) {
      return (<InfoPage
                text={"A teszt töltődik..."}
                title={"Kis türelmet..."}/>
              );
    } else if (this.state.num === -2) {
      return (<InfoPage
                text={"Hibás email cím<br/>\n"+
                  "A helyzet megoldása érdekében, kérlek írj az Ügyfélszolgálatnak: <a href=\"mailto:info@studicore.hu\">info@studicore.hu</a><br/>"}
                title={"Hiba"}/>
              );
    } else if (this.state.num === -1) {
      return (<InfoPage
                text={"Hiányzó email cím<br/>\n"+
                  "A helyzet megoldása érdekében, kérlek írj az Ügyfélszolgálatnak: <a href=\"mailto:info@studicore.hu\">info@studicore.hu</a><br/>"}
                title={"Hiba"}/>
              );
    } else if (this.state.num === 0) {
      return (<ResultPage
                mmdata={this.state.mmdata}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}/>
              );
    } else if (this.state.num === 1) {
      return (<InputQuiz
                text={
                "<center>A tesztet többször is kitöltheted.\n" +
                "A teszt kitöltési ideje kb. 20-30 perc.</center>\n\n" +
			//	"A teszt jelenleg nem támogatja iOS-en futó Safari böngészőket</center>\n\n" +
                "Sütit készülünk sütni.\n" +
                "Van otthon 1 kg cukrunk, 12 tojásunk, 146 dkg lisztünk.\n" +
                "A süti egy adagjának elkészítéséhez 10 dkg cukor, 2 tojás és 20 dkg liszt kell.\n" +
                "Hány egész adag süteményt tudunk elkészíteni?"}
                num={this.state.num}
                all={all}
                value={this.state.answers[1].value}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChange={(e) => this.handleChange(this.state.num,e)}/>);
    } else if (this.state.num === 2) {
      return (<CheckBoxQuiz
                text={<div>Az előző feladat szövege ez volt:<br/>
                  <blockquote>Sütit készülünk sütni.<br/>
                  Van otthon 1 kg cukrunk, 12 tojásunk, 146 dkg lisztünk.<br/>
                  A süti egy adagjának elkészítéséhez 10 dkg cukor, 2 tojás és 20 dkg liszt kell.<br/>
                  Hány egész adag süteményt tudunk elkészíteni?</blockquote>
                  Ha a konkrét mennyiségek helyett <code>a</code>, <code>b</code>, <code>c</code>, <code>d</code>, <code>e</code>, <code>f</code> paraméterek szerepelnének,<br/>
                  mely válaszok lennének igazak a kalkulációra?</div>}
                num={this.state.num}
                all={all}
                options={[
                  {value: 'a', label: 'a számítás alapja az, ami a receptben legnagyobb tömegben (darabban) szükséges'},
                  {value: 'b', label: 'osztani tuti kell benne'},
                  {value: 'c', label: 'bizonyos részeredmények közül a legkisebbet kell kiválasztani'},
                  {value: 'd', label: 'az biztos, hogy ki kell számítani a recept által előírt és a meglévő mennyiség viszonyát'},
                  {value: 'e', label: 'bizonyos részeredmények közül a legnagyobbat kell kiválasztani'},
                  {value: 'f', label: 'megnézzük, melyik anyag áll a legnagyobb tömegben (darabban) rendelkezésre'}
                ]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(e) => this.handleChangeToValue(this.state.num,e)}/>);
    } else if (this.state.num === 3) {
      return (<InputQuiz
                text={"Sütit készülünk sütni.\n" +
                "Van otthon 1 kg cukrunk, 12 tojásunk, 46 dkg lisztünk.\n" +
                "A süti egy adagjának elkészítéséhez 10 dkg cukor, 2 tojás és 20 dkg liszt kell.\n" +
                "Sajnos azt vettük észre, hogy az otthon lévő összetevők mindegyike holnapra megromlik, így fel akarjuk használni az egészet.\n" +
                "Mennyi cukrot, tojást és lisztet kell vásárolnunk ahhoz, hogy az otthon lévő összetevők mind elfogyjanak?\n" +
                "A válasz formátuma: <code>36 dkg cukor, 5 tojás, 12 dkg liszt.</code>\n" +
                "Figyelj a formátumra! Minden szóköz, írásjel számít!"}
                num={this.state.num}
                all={all}
                value={this.state.answers[3].value}
                inputSize="40"
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChange={(e) => this.handleChange(this.state.num,e)}/>);
      //return (<Q3 onEvaluate={(e) => this.handleSubmit(3,e)}  onChange={(e) => this.handleChange(3,e)}/>);
    } else if (this.state.num === 4) {
      var operands = [{value: 'a', label: 'a'}, {value: 'b', label: 'b'}, {value: 'c', label: 'c'}, {value: 'd', label: 'd'}, {value: 'e', label: 'e'}, {value: 'f', label: 'f'}, {value: '1', label: '1'}, {value: '2', label: '2'}, {value: '3', label: '3'}];
      var operators = [{value: 'plus', label: '+'}, {value: 'minus', label: '-'}, {value: 'mul', label: '*'}, {value: 'div', label: '/'}];
      var aggregates = [{value: 'min', label: 'minimumát'}, {value: 'sum', label: 'összegét'}, {value: 'max', label: 'maximumát'}, {value: 'mul', label: 'szorzatát'}, {value: 'log', label: 'logaritmusát'}];
      return (<TextInternalSelectQuiz
                text={["Sütit készülünk sütni.\nVan otthon <code>a</code> dkg cukrunk, <code>b</code> db. tojásunk, <code>c</code> dkg lisztünk.\n" +
                       "A süti egy adagjának elkészítéséhez <code>d</code> dkg cukor, <code>e</code> db. tojás és <code>f</code> dkg liszt kell.\n\n" +
                      "Sajnos azt vettük észre, hogy az otthon lévő összetevők mindegyike holnapra megromlik, így fel akarjuk használni az egészet úgy, hogy amiből szükséges, abból vásárolunk még hozzá, hogy pont elfogyjon az eredeti mennyiség.\n\n" +
                      "A megoldáshoz először kiszámítjuk, hogy a cukrunk hány adag elkészítésére elegendő, amit a fenti adatokból az alábbi művelettel számítunk ki:\n", /*0*/ "", /*1*/ "", /*2*/
                      "\nMeghatározzuk, hogy a tojásunk hány adag elkészítésére elegendő, amit a fenti adatokból az alábbi műveletekkel számítunk ki:\n", /*3*/ "", /*4*/ "", /*5*/
                      "\nVégül a liszttel is ugyanezt végezzük el:\n", /*6*/ "", /*7*/ "", /*8*/
                      "\nEzek után vesszük a kapott arányok", /*9*/ "."]}
                num={this.state.num}
                all={all}
                options={[
                  operands, operators, operands,
                  operands, operators, operands,
                  operands, operators, operands,
                  aggregates
                ]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
              />);
    } else if (this.state.num === 5) {
      /*return (<DragAndDropQuiz
                text={"Van egy robotteknőcünk, ami egy fehér papírlapon áll éppen. A robotteknőc az orrával vonalat tud húzni. Kiinduláskor a robotteknőc orra északra (felfelé) néz.\n" +
                "A feladatunk az, hogy irányítsuk a robotteknőcöt úgy, hogy az alábbi ábra alakuljon ki, majd a végén szintén északra (felfelé) nézzen a robotteknőc.\n" +
                "(Az oldalak mellett azok hossza, a csúcsoknál az ottani szög szerepel).\n\n" +
                "<img src='https://www.programozas-oktatas.hu/images/teknoc_haromszog.png' alt='egy derékszögű háromszög, melynek a magassága 50, szélessége 120, átfogója 130 egység hosszú. A teknőc a derékszögben áll és az 50-es oldal túlsó vége felé néz. Az 50-es oldal végénél lévő belső szög 67,38 fok, a hosszabb befogó túlsó végén lévő 22,62 fok'/>\n\n" +
                "A használható utasítások:" +
                "<ul>" +
                "<li><code>ELORE(x)</code> - a teknőc előre megy <code>x</code> pozíciónyit, ahol <code>x</code> tetszőleges szám lehet</li>" +
                "<li><code>JOBBRA(y)</code> - a teknőc jobbra fordul <code>y</code> fokot (°), ahol <code>y</code> tetszőleges szám lehet</li>" +
                "<li><code>BALRA(z)</code> - a teknőc balra fordul <code>z</code> fokot (°), ahol <code>z</code> tetszőleges szám lehet</li>" +
                "</ul>" +
                "Törekedjük a legkevesebb utasításból álló megoldásra!\n" +
                "Húzogasd az utasításokat tartalmazó téglalapokat a lyukakba!\n"}
                num={this.state.num}
                all={all}
                places="8"
                possibilities={[
                  "JOBBRA(112,62)", "JOBBRA(157,38)", "JOBBRA(22,62)",
                  "BALRA(67,38)", "ELORE(50)", "BALRA(112,62)",
                  "JOBBRA(67,38)", "BALRA(22,62)", "ELORE(130)",
                  "JOBBRA(90)", "ELORE(120)", "BALRA(157,38)"]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
                />);*/
      return (<DragAndDropQuiz2
                text={"Van egy robotteknőcünk, ami egy fehér papírlapon áll éppen. A robotteknőc az orrával vonalat tud húzni. Kiinduláskor a robotteknőc orra északra (felfelé) néz.\n" +
                "A feladatunk az, hogy irányítsuk a robotteknőcöt úgy, hogy az alábbi ábra alakuljon ki, majd a végén szintén északra (felfelé) nézzen a robotteknőc.\n" +
                "(Az oldalak mellett azok hossza, a csúcsoknál az ottani szög szerepel).\n\n" +
                "<img src='https://www.programozas-oktatas.hu/images/teknoc_haromszog.png' alt='egy derékszögű háromszög, melynek a magassága 50, szélessége 120, átfogója 130 egység hosszú. A teknőc a derékszögben áll és az 50-es oldal túlsó vége felé néz. Az 50-es oldal végénél lévő belső szög 67,38 fok, a hosszabb befogó túlsó végén lévő 22,62 fok'/>\n\n" +
                "A használható utasítások:" +
                "<ul>" +
                "<li><code>ELORE(x)</code> - a teknőc előre megy <code>x</code> pozíciónyit, ahol <code>x</code> tetszőleges szám lehet</li>" +
                "<li><code>JOBBRA(y)</code> - a teknőc jobbra fordul <code>y</code> fokot (°), ahol <code>y</code> tetszőleges szám lehet</li>" +
                "<li><code>BALRA(z)</code> - a teknőc balra fordul <code>z</code> fokot (°), ahol <code>z</code> tetszőleges szám lehet</li>" +
                "</ul>" +
                "Húzogasd át az alábbi (szürke alapon lévő) utasításokat olyan sorrendbe, hogy a teknőc a megfelelő ábrát rajzolja.\n" +
                "Az utolsó utasítás a VÉGE legyen!\n"+
                "A VÉGE utáni utasításokat a teknőc már nem hajtja végre!\n" +
                "Törekedj a legkevesebb utasításból álló megoldásra!\n\n"
              }
                num={this.state.num}
                all={all}
                places="8"
                possibilities={["not implemented"]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
                />);
    } else if (this.state.num === 6) {
      return (<CheckBoxQuiz
                text={[<p key="1">Kérlek olvasd el az alábbi szöveget, és értsd meg a benne foglaltakat:<br/></p>,
                  <ShowHide key="2"/>,
                  <p key="3">Jelöld be az összes igaz választ!<br/></p>]}
                num={this.state.num}
                all={all}
                options={[
                  {value: 'a', label: 'a változó egyidőben több adatot is tárolhat'},
                  {value: 'b', label: 'a számítógépek adatokkal dolgoznak'},
                  {value: 'c', label: 'az "int a;" utasítás hatására létrejön egy "a" nevű változó'},
                  {value: 'd', label: 'az "=" hatására a jobb oldalon álló értékkel felülírjuk a bal oldalon álló változó tartalmát'},
                  {value: 'e', label: 'a változó értéke a program végéig az marad, amit először kapott'},
                  {value: 'f', label: 'a számítógép ismeri az eltárolt adat értelmét'},
                  {value: 'g', label: 'a változónak van neve'}
                ]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(e) => this.handleChangeToValue(this.state.num,e)}/>);
    } else if (this.state.num === 7) {
      return (<TextAreaQuiz
                text={[<div key="1">
                  <p>Adott a következő programrészlet:</p>
                  <code>int a = 7;<br/>int b = 3;</code>
                  <p>Cseréljük meg a két változóban lévő adatot úgy, hogy</p>
                  <ul>
                  <li>nem írunk le számjegyet</li>
                  <li>nem változtatjuk meg a programrészletet</li>
                  <li>kizárólag új sorokat írhatunk a programrészlet végére</li>
                  <li>nem használunk ékezetes betűket</li>
                  </ul>
                  Mit írjunk a fenti programrészlet alá?<br/><br/>
                  Az előző feladat változókkal kapcsolatos leírása:
                  </div>,
                  <ShowHide key="2"/>,
                  <p key="3">Az alábbi szövegmezőbe írd be a megoldást!</p>]
                }
                num={this.state.num}
                all={all}
                initial="int a = 7;\nint b = 3;\n"
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
                />);
    } else {
      return (<Evaluation answers={this.state.answers}
                mmdata={this.state.mmdata}
                onEvaluate={(e) => this.restartTest()}
                />);
    }
  }
}

/*class Proba extends React.Component {
  constructor(props) {
    super(props)
    this.state = {adContent: 'Töltődik...'};
    var self = this;
    axios.get('https://www.studicore.hu/hirdetes/teszt-vege/')
    .then(function(response) {
      //console.log(response);
      var el = document.createElement("html");
      el.innerHTML = response.data;
      document.body.appendChild(el);
      var adContent = document.getElementById("hirdetes").innerHTML;
      document.body.removeChild(el);
      self.setState({adContent: adContent});
    })
    .catch(function(error) {
      console.log(error);
    })
  }
  render() {
    const adContent = this.state.adContent;
    //const adDiv = <div dangerouslySetInnerHTML={{__html: adContent}}></div>
    //return (adDiv);
    const adPage =
      <div>
      Az oldal hamarosan automatikusan átirányít
      <InfoPage
        title="Bosszankodásmentes számítógépválasztó -50%"
        text={adContent} />
      </div>
    return (adPage)
  }
}*/
//ReactDOM.render(<Proba />, document.getElementById('root'));
ReactDOM.render(<ProgQuiz />, document.getElementById('root'));
registerServiceWorker();

/* Mintaként: RadioQuiz
return (<RadioQuiz
          text="Kérdés szövege"
          num={this.state.num}
          options={[
            {value: 'a', label: 'a változó egyidőben több adatot is tárolhat'},
            {value: 'b', label: 'a számítógépek adatokkal dolgoznak'},
            {value: 'c', label: 'az "int a;" utasítás hatására létrejön egy "a" nevű változó'},
            {value: 'd', label: 'az "=" hatására a jobb oldalon álló értékkel felülírjuk a bal oldalon álló változó tartalmát'},
            {value: 'e', label: 'a változó értéke a program végéig az marad, amit először kapott'},
            {value: 'f', label: 'a számítógép ismeri az eltárolt adat értelmét'},
            {value: 'g', label: 'a változónak van neve'}
          ]}
          onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
          onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
        />);*/
