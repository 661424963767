import React from 'react';
import './index.css';

class Valtozok extends React.Component {
    render() {
        return (
          <div className="valtozok">
          <h1>Változók</h1>
        <p>A programok adatokat tárolnak és kezelnek. A hétköznapi
        életben is találkozunk adatokkal: mekkora az infláció mértéke, hányan élnek
        Magyarországon, hányadik az ország az egy főre jutó öngyilkosságok számában, mi
        Magyarország államformája (köztársaság), és így tovább.</p>

        <p>Amikor programot írunk, ilyen, és ehhez hasonló adatokkal
        dolgozunk, persze nem feltétlenül országok statisztikájával foglalkozunk,
        lehet, hogy más tématerületen készül a programunk (pl. egy adott gazdaság
        tenyészmarha-állományának számával dolgozunk), de a lényeg ugyanaz: valamilyen
        adatok vannak a központban.</p>

        <p>Az adatok értelmét a számítógép nem ismeri, neki az &quot;1,4&quot;
        lehet az adott év inflációjának a mértéke is, az ezer főre jutó öngyilkosságok
        száma is, és az egy tenyészmarhára jutó borjak számának átlaga is. A program
        csak magukkal a számokkal (esetleg másfajta adatokkal) foglalkozik.</p>

        <p>Az adatokat a feldolgozáshoz ún. változókban tárolják.</p>

        <ul>
        <li>Egy változó egyetlen adatot tárol<sup>1</sup></li>
        <li>A változóban tárolt adatot meg tudjuk változtatni</li>
        <li>A változóban tárolt adat megváltoztatásakor a korábbi adat eltűnik.</li>
        <li>A változóknak van neve</li>
        </ul>

        <p>Pl. a programunkban az infláció mértékével foglalkozunk,
        ehhez létrehozunk egy <code>lelekszam</code> nevű változót.
        A változóba (elképzelhetjük egy fióknak, vagy bármilyen tárolónak) betesszük az
        aktuális értéket (mondjuk 9.900.000). Majd megváltoztatjuk az <code>lelekszam</code> változó értékét 9.850.000-re, és akkor a
        korábbi 9.900.000 örökre eltűnik.</p>

        <p>Ahhoz, hogy megőrizzük a változó korábbi értékét, felülírás
        előtt átmenthetjük egy másik változóba, azaz pl. az <code>lelekszam</code>
        értékét egy <code>regiLelekszam</code> nevű változóba
        mentjük át.</p>

        <p>Hogyan néz ki mindez Javában? A pontosvessző előtti rész az
        ún. utasítás, ami megmondja a számítógépnek, mit csináljon. Előbb az utasítást
        írom le, majd a magyarázatot.</p>

        <pre>int lelekszam = 9900000;</pre>

        <p>megmondjuk a számítógépnek, hogy hozza létre a <code>lelekszam</code> változót, és adja értékül neki a
        9.900.000-et</p>

        <pre>int regiLelekszam;</pre>

        <p>megmondjuk a számítógépnek, hogy hozza létre a <code>regiLelekszam</code> változót, melynek egyelőre nem lesz
        értéke.</p>

        <pre>regiLelekszam = lelekszam;</pre>

        <p>a <code>regiLelekszam</code> ezentúl a <code>lelekszam</code> aktuális értékét fogja tárolni
        (9.900.000). Itt fontos azt is megjegyezni, hogy csak az érték másolódik át,
        tehát a két változó tartalma egymástól teljesen független, így ha az egyiket
        megváltoztatjuk, a másik nem változik vele.</p>

        <pre>lelekszam = 9850000;</pre>

        <p>a <code>lelekszam</code> értékét
        megváltoztatjuk, ezentúl 9.850.000 lesz benne. (<code>regiLelekszam</code>
        marad 9.900.000, mivel ugye nincsenek összekötve).</p>

        <p>Egyben:</p>

        <pre>
        int lelekszam = 9900000;<br/>
        int regiLelekszam;<br/>
        regiLelekszam = lelekszam;<br/>
        lelekszam = 9850000;<br/>
        </pre>

        <hr align="left" size="1" width="33%"/>

        <div id="ftn1">

        <p><sup>1</sup> Ez valójában nem teljesen igaz, de egyelőre maradjunk annyiban, hogy egy
        változó egy adatot tárol.</p>

        </div>

          </div>

        );
    }
}

export default Valtozok;
