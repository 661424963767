import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
//import SAPForm from './SAPForm';
import axios from 'axios';
import queryString from 'query-string';
import Progress from 'react-progressbar';
import MediaQuery from 'react-responsive'

//const queryString = require('query-string');

class Evaluation extends React.Component {
  constructor(props) {
    super(props);
    const parsed1 = queryString.parse(window.location.search);
    const email = parsed1.email;

    this.state = { points: 0, num: 0, exnumber: 7, email: email,
      1: {points: '?', review: 'Letöltés...', color: '#FFFFFF'},
      2: {points: '?', review: 'Letöltés...', color: '#FFFFFF'},
      3: {points: '?', review: 'Letöltés...', color: '#FFFFFF'},
      4: {points: '?', review: 'Letöltés...', color: '#FFFFFF'},
      5: {points: '?', review: 'Letöltés...', color: '#FFFFFF'},
      6: {points: '?', review: 'Letöltés...', color: '#FFFFFF'},
      7: {points: '?', review: 'Letöltés...', color: '#FFFFFF'}
    };
    var self = this;
    //var answer7 = props.answers[7] ? props.answers[7].value : '';
    //var testengine = 'http://localhost:8080/';
    //var testengine = 'https://teszt.studicore.hu:2500/';
      /* redirect: teszt.studicore.hu > Apache & nginx Settings > Additional nginx directives:
        location /webjext {
	        rewrite ^/webjext/(.*) /$1 break;
          proxy_pass https://127.0.0.1:2500;
        }
      */
    var testengine = 'https://teszt.studicore.hu/webjext/';
    var saveurl = 'https://auto.studicore.hu/integration/procedure_mm_write_testresult.php';
    //var saveurl = 'https://auto.studicore.hu/integration/xxx.php';
    //var saveurl='http://httpbin.org/post';
    saveResults(saveurl, email, props.answers, -100);
    evaluateAnswer(self, 1, props.answers, testengine, 'teszt1', 5, function(points) {saveResults(saveurl, email, props.answers, points);});
    evaluateAnswer(self, 2, props.answers, testengine, 'teszt2', 10, function(points) {saveResults(saveurl, email, props.answers, points);});
    evaluateAnswer(self, 3, props.answers, testengine, 'teszt3', 15, function(points) {saveResults(saveurl, email, props.answers, points);});
    evaluateAnswer(self, 4, props.answers, testengine, 'teszt4', 20, function(points) {saveResults(saveurl, email, props.answers, points);});
    evaluateAnswer(self, 5, props.answers, testengine, 'teszt5', 15, function(points) {saveResults(saveurl, email, props.answers, points);});
    evaluateAnswer(self, 6, props.answers, testengine, 'teszt6', 10, function(points) {saveResults(saveurl, email, props.answers, points);});
    evaluateAnswer(self, 7, props.answers, testengine, 'teszt7', 25, function(points) {saveResults(saveurl, email, props.answers, points);});
  }
  render() {
    //console.log(this.props.mmdata);

    var readableAnswers = [];

    var answer1 = this.props.answers[1] ? this.props.answers[1].value : '';
    readableAnswers[1] = answer1;

    var answer2 = this.props.answers[2] ? this.props.answers[2].value : '';
    readableAnswers[2] = checkboxAnswer(answer2);

    var answer3 = this.props.answers[3] ? this.props.answers[3].value : '';
    readableAnswers[3] = answer3;

    var answer4 = this.props.answers[4] ? this.props.answers[4].value : '';
    readableAnswers[4] = dropDownAnswer(answer4);

    var answer5 = this.props.answers[5] ? this.props.answers[5].value : '';
    readableAnswers[5] = dragAndDropAnswer(answer5);

    var answer6 = this.props.answers[6] ? this.props.answers[6].value : '';
    readableAnswers[6] = checkboxAnswer(answer6);

    var answer7 = this.props.answers[7] ? this.props.answers[7].value : '';
    readableAnswers[7] = answer7;

    var bigEvaluationTable;
      var evalTable = [];
      for (var i = 1; i <= 7; i++) {
        evalTable.push(
          <tr key={i} bgcolor={this.state[i].color}>
            <td className="evaluation-id">{i}</td>
            <td className="evaluation-answer">{readableAnswers[i]}</td>
            <td className="evaluation-solution">{this.state[i].solution}</td>
            <td className="evaluation-points">{this.state[i].points}</td>
            <td className="evaluation-review">{this.state[i].review}</td>
          </tr>);
      }
      bigEvaluationTable =
      <table className="evaluation">
        <colgroup>
          <col width="5%"/>
          <col width="30%"/>
          <col width="30%"/>
          <col width="5%"/>
          <col width="30%"/>
        </colgroup>
        <tbody>
          <tr>
            <th className="evaluation-id">#</th>
            <th className="evaluation-answer">Válaszod</th>
            <th className="evaluation-solution">Helyes</th>
            <th className="evaluation-points">Pont</th>
            <th className="evaluation-review">Magyarázat</th>
          </tr>
          {evalTable}
        </tbody>
      </table>;
    //var styles = {7: {backgroundColor: this}}
    var smallEvaluationTable = [];
    for (i = 1; i <= 7; i++) {
      smallEvaluationTable.push(
        <div key = {i} className="evaluation">
          <div className="evaluation-one-block" style={{backgroundColor: this.state[i].color}}>
            <div className="evaluation-one-row">
              <div className="evaluation-id evaluation-header">Feladat sorszáma</div>
              <div className="evaluation-id">{i}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-answer evaluation-header">Válaszod</div>
              <div className="evaluation-answer">{readableAnswers[i]}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-solution evaluation-header">Helyes</div>
              <div className="evaluation-solution">{this.state[i].solution}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-points evaluation-header">Pont</div>
              <div className="evaluation-points">{this.state[i].points}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-review evaluation-header">Magyarázat</div>
              <div className="evaluation-review">{this.state[i].review}</div>
            </div>
          </div>
        </div>
        );
    }

    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            <h1>Értékelés</h1>
            <Progress completed={100} color={"#1380AE"}/>
          </div>
        </div>
        <div className="hatter">
          <div className="evaluation-wrapper">
            <MediaQuery minWidth={601}>
              {bigEvaluationTable}
            </MediaQuery>
            <MediaQuery maxWidth={600}>
              {smallEvaluationTable}
            </MediaQuery>
          </div>
        <Points points={this.state.points} num={this.state.num} email={this.state.email} onEvaluate={this.props.onEvaluate} mmdata={this.props.mmdata}/>
      </div>
      </div>
    );
  }
}

function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}

function evaluateAnswer(self, index, answers, testengine, url, maxPoints, finished) {
  var answer = self.props.answers[index] ? self.props.answers[index].value : '';
  if (typeof answer === "string")
    answer = answer.replace(/\+/g, "%2B");
  axios.post(testengine + url, answer)
    .then(function(response) {
      let color;
      if (response.data.points === 100) {
        color = "#d4edda"; //"#21ff28"; //"#25cc16"; // zöld: "#009900";
      } else if (response.data.points === 0) {
        color = "#f8d7da"; //"#ff3b21";//"#e84040"; // piros: "#990000";
      } else {
        color = "#fff3cd"; //"#f2e93c"; // sárga: "#cc8502";
      }
      let myPoints = response.data.points * maxPoints / 100;
      var myState = {};
      myState[index] = {points: myPoints, review: response.data.review, color: color, solution: response.data.solution};
      self.setState(myState);
      self.setState({points: self.state.points + myPoints, num: self.state.num+1});
      if (self.state.num === self.state.exnumber) {
        sleep(1700);
        finished(self.state.points);
      }
    })
    .catch(function (error) {
      var myState = {};
      myState[index] = {points: 0, review: "Belső hiba!", color: '#FF0000', solution: ''};
      self.setState(myState);
      self.setState({num: -100});
      console.log(error);
    });
}

function saveResults(saveurl, email, answers, points) {
  if (!(email == null)) {
    // email létezik, elmentjük az SAP-be az adatokat
    let data = new FormData();
    data.append('email', email);
    data.append('points', points);
    data.append('data', JSON.stringify(answers));
    axios.post(saveurl, data)
    .then(function(response) {
      //console.log(response);
    }).catch(function(error) {
      console.log(error);
    });
  } else {
    // email nem létezik
  }
}

function checkboxAnswer(answer) {
  var chars = "abcdefghijklmnopqrstuvwxyz";
  var ret = '';
  var first = true;
  for (var i = 0; i <= chars.length; i++) {
    if (answer[chars.charAt(i)]) {
      if (!first) {
        ret += ', ';
      }
      first = false;
      ret += chars.charAt(i);
    }
  }
  if (ret === '') {
    ret = 'nincs';
  }
  return 'Bejelölt válaszok: '+ret;
}

function dragAndDropAnswer(answer) {
  var ret = '';
  var first = true;
  for (var i = 0; i <= 30; i++) {
    if (answer[i] != null) {
      if (!first) {
        ret += ', ';
      }
      first = false;
      ret += answer[i];
    }
  }
  return ret;
}

function dropDownAnswer(answer) {
  var ret = '';
  var first = true;
  for (var i = 0; i <= 30; i++) {
    if (answer[i] != null) {
      if (!first) {
        ret += ', ';
      }
      first = false;
      ret += answer[i].label;
    }
  }
  return ret;
}

class Points extends React.Component {
/*  constructor(props) {
    super(props);
  }*/
  render() {
    const points = Math.round(this.props.points * 100) / 100;
    //const points = 50;
    //const email = this.props.email;
    //console.log(this.props.num);
    if (this.props.num < 0) {
      return (
        <div className="erroreval eval">
          Belső hiba! Kérlek értesítsd az ügyfélszolgálatot (info@studicore.hu)
        </div>
      );
    }
    if (this.props.num < 7) {
      return (
        <div className="eval">
          Kiértékelés folyamatban...
        </div>
      );
    }
    if (points >= 90) {
      return (
        <div>
          <div className="excellenteval eval">
            Összes elért pont: {points} / 100 = {points}%<br/>
            <b>WOW! Gratulálok! Nagyszerű eredmény, kiváló!<br/>
            Mindenképpen érdemes programozással foglakoznod!</b><br/><br/>
            Az eredményt emailben is elküldjük.<br/>
            <BackToHomePage/>
          </div>
          {/*<div className="infoeval eval">
            <SAPForm points={points} email={email} mmdata={this.props.mmdata}/>
          </div>*/}
        </div>
      );
    } else if (points >= 55) {
      return (
        <div>
          <div className="mediumeval eval">
            Összes elért pont: {points} / 100 = {points}%<br/>
            <b>Ez elég jól sikerült, gratulálok. Érdemes programozással foglalkoznod!</b><br/><br/>
            Az eredményt emailben is elküldjük.<br/>
            <BackToHomePage/>
          </div>
          {/*<div className="infoeval eval">
            <SAPForm points={points} email={email} mmdata={this.props.mmdata}/>
          </div>*/}
        </div>
      );
    } else if (points >= 0){
      //console.log(this.props.onEvaluate);
      return (
        <div className="badeval eval">
          Összes elért pont: {points} / 100 = {points}%<br/>
          <b>Nagyon köszönjük, hogy kitöltötted a tesztet.</b><br/><br/>
          Az eredményt emailben is elküldjük.<br/>
          <BackToHomePage/>
        </div>
      );
    } else {
      return null;
    }
  }
}

class BackToHomePage extends React.Component {
  render() {
    return <a href="https://www.studicore.hu" className="gomb">Vissza a StudiCore honlapra</a>;
  }
}

export default Evaluation;
